/* components/Rohstoffe.css */

/* Container für alle Rohstoffe */
.rohstoffe-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Abstand zwischen den Ressourcen */
  padding: 10px;
  background-color: #2c3e50; /* Hintergrundfarbe des Containers */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Einzelne Ressource */
.resource {
  position: relative; /* Notwendig für das Positionieren der Tooltips */
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #435d76b1; /* Hintergrundfarbe der Ressource */
  border: 1px solid #dddddd00; /* Rahmen der Ressource */
  border-radius: 8px;
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer; /* Zeigt an, dass es interaktiv ist */
}

/* Hover-Effekt für Ressourcen */
.resource:hover {
  transform: translateY(-5px); /* Leichter Anheben-Effekt beim Hover */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

/* Icon der Ressource */
.resource-icon {
  width: 40px; /* Größe des Icons */
  height: 40px;
  margin-right: 10px; /* Abstand zum Wert */
}

/* Wert der Ressource */
.resource-value {
  font-size: 1em;
  font-weight: bold;
  color: #d1d0d0;
}

/* Spezifisches Styling für Gold */
.resource-value.gold {
  color: #ffd700; /* Goldene Farbe für den Goldwert */
}

/* Benutzerdefinierter Tooltip */
.custom-tooltip {
  font-size: 1em;
  padding: 8px 12px;
  background-color: #333 !important; /* Hintergrundfarbe des Tooltips */
  color: #fff !important; /* Textfarbe des Tooltips */
  border: 1px solid #555 !important; /* Rahmenfarbe des Tooltips */
  border-radius: 6px !important; /* Abgerundete Ecken */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2) !important; /* Schatten für den Tooltip */
}

/* Anpassung des Pfeils des Tooltips */
.custom-tooltip::after {
  border-top-color: #333 !important; /* Farbe des Pfeils */
}

/* Optional: Transition-Effekt für den Tooltip */
.custom-tooltip {
  transition: all 0.3s ease-in-out;
}

/* Responsive Anpassungen */
@media (max-width: 600px) {
  .rohstoffe-container {
    justify-content: center;
  }

  .resource {
    width: 100%;
    max-width: 300px;
  }
}
