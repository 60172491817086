/* Gebaeude.css */

.tabs-container {
    display: flex;
    justify-content: space-around;
    margin-top: 2px;
    margin-bottom: 2px;
  }
  
  .tab-button {
    background-color: #34495e;
    color: #ecf0f1;
    border: none;
    padding: 10px 20px;
    font-size: 1.1em;
    cursor: pointer;
    border-radius: 10px;
    transition: background-color 0.3s ease, color 0.3s ease;
    flex: 1;
    text-align: center;
    margin: 0 5px; /* Abstand zwischen den Reitern */
  }
  
  .tab-button:hover {
    background-color: #1abc9c; /* Hover-Farbe */
    color: #fff;
  }
  
  .tab-button.active {
    background-color: #16a085; /* Farbe für aktiven Reiter */
    color: #fff;
  }

  .gebaeude-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    max-width: 62.5rem; /* Maximal 1000px */
    margin: 0 auto;
  }
  
  .gebaeude-item {
    flex: 1 1 100%; /* Volle Breite auf kleineren Bildschirmen */
    margin-bottom: 30px;
    padding: 20px;
    background-color: #34495e;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column; /* Stellt sicher, dass Inhalt vertikal gestapelt wird */
    position: relative;
    overflow: visible; /* Verhindert, dass Inhalte abgeschnitten werden */
    max-width: calc(50% - 50px); /* Maximal zwei Gebäude nebeneinander, falls genügend Platz */
    height: auto; /* Entfernt die feste Höhe */
}
  
.gebaeude-info {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6); /* Halbtransparenter Overlay-Effekt */
  color: #ecf0f1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  height: auto; /* Dynamische Höhe je nach Inhalt */
}
  
  .gebaeude-info h2 {
    margin-bottom: 10px;
    color: #1abc9c;
    font-size: 1.5em;
  }
  
  .gebaeude-info p {
    margin: 5px 0;
    font-size: 1em;
    background: rgba(0, 0, 0, 0.6);
  }
  
  .gebaeude-info ul {
    list-style: none;
    padding: 10px;
    margin: 5px 0;
    background-color: #2c3e50a1; /* Hintergrund für die gesamte Liste */
    border-radius: 5px;
    width: auto;
    align-self: flex-start;
  }
  
  .gebaeude-info ul li {
    padding: 5px 0; /* Weniger Padding, um die Items kompakt zu halten */
    color: #ecf0f1;
    font-size: 0.9em;
  }
  
  .gebaeude-info button {
    background-color: #1abc9c;
    border: none;
    padding: 10px 20px;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: auto; /* Setzt die Breite auf den Textinhalt */
    align-self: flex-start;
  }
  
  .gebaeude-info button:disabled {
    background-color: #7f8c8d;
    cursor: not-allowed;
  }
  
  .gebaeude-info button:hover:not(:disabled) {
    background-color: #16a085;
  }

  .menu-container {
    position: fixed; /* Stellt sicher, dass das Menü immer sichtbar bleibt */
    bottom: 0;
    left: 0;
    width: 100%; /* Volle Breite */
    background-color: #34495e; /* Beispielhintergrund für das Menü */
    z-index: 5; /* Höherer z-index, um das Menü über den Gebäuden anzuzeigen */
    padding: 10px;
    text-align: center;
  }
  
  /* Responsives Layout */
  @media (max-width: 768px) {
    .gebaeude-item {
      flex: 1 1 100%; /* Volle Breite bei kleineren Bildschirmen */
      height: 500px;
    }
  
    .gebaeude-info h2 {
      font-size: 1.2em;
    }
  
    .gebaeude-info button {
      font-size: 0.9em;
      padding: 8px 16px;
    }
  }

  /* Gebaeude.css */
.arbeiter-slider {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.arbeiter-slider label {
  margin-right: 10px;
}

.arbeiter-slider input[type="range"] {
  flex: 1;
  margin-right: 10px;
}

@media (max-width: 375px) {
  .tabs-container {
    flex-direction: column; /* Tabbereich in eine Spalte umwandeln */
    margin: 10px 0;
  }

  .tab-button {
    font-size: 1em; /* Kleinere Schriftgröße */
    padding: 8px 15px; /* Weniger Padding */
    margin: 5px 0; /* Abstand zwischen den Tabbereichen */
  }

  .gebaeude-container {
    flex-direction: column; /* Verhindert das Nebeneinander der Gebäude */
    gap: 10px; /* Weniger Abstand zwischen den Gebäude-Items */
    padding: 10px;
    max-width: 100%; /* Volle Breite des Bildschirms */
    margin-bottom: 50px;
  }

  .gebaeude-item {
    flex: 1 1 100%; /* Volle Breite für jedes Gebäude-Item */
    height: auto; /* Passt die Höhe automatisch an den Inhalt an */
    max-width: 100%; /* Volle Breite des Containers */
    padding: 10px; /* Weniger Padding */
    height: 200px;
  }

  .gebaeude-info h2 {
    font-size: 1.2em; /* Kleinere Schriftgröße */
  }

  .gebaeude-info p {
    font-size: 0.9em; /* Kleinere Schriftgröße */
  }

  .gebaeude-info ul {
    padding: 8px;
    margin: 5px 0;
  }

  .gebaeude-info ul li {
    font-size: 0.85em; /* Kleinere Schriftgröße */
    padding: 3px 0; /* Weniger Padding */
  }

  .gebaeude-info button {
    font-size: 0.8em;
    padding: 8px 14px; /* Kleinere Schaltflächen */
  }

  .arbeiter-slider {
    flex-direction: column; /* Slider und Label untereinander anordnen */
    align-items: flex-start;
  }

  .arbeiter-slider label {
    margin-bottom: 5px; /* Abstand zum Input-Element */
  }

  .arbeiter-slider input[type="range"] {
    width: 100%; /* Volle Breite des Bildschirms */
  }

  
}
