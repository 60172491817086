/* src/components/Notification.css */
.notification {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7); /* Halbtransparentes Schwarz */
  color: #fff;
  padding: 15px 25px;
  border-radius: 5px;
  z-index: 1000;
  transition: opacity 0.5s ease-in-out;
  display: flex;
  align-items: center;
  min-width: 300px;
}

.notification.success {
  background-color: rgba(46, 204, 113, 0.9); /* Grün für Erfolg */
}

.notification.error {
  background-color: rgba(231, 76, 60, 0.9); /* Rot für Fehler */
}

.notification p {
  margin: 0;
  font-size: 16px;
  flex-grow: 1;
}

.close-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  margin-left: 15px;
  cursor: pointer;
  outline: none;
}

.close-button:hover {
  color: #ccc;
}
