/* components/SideMenu.css */

.side-menu {
  width: 80px; /* Kleinere Breite, da nur Icons angezeigt werden */
  padding: 20px;
  background-color: #2c3e50;
  color: #ecf0f1;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center; 
  margin-left: 2px;
  margin-right: 2px;
}

.side-menu-button {
  background-color: #34495e;
  color: #ecf0f1;
  border: none;
  padding: 15px;
  font-size: 1em;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  display: flex;
  justify-content: center; /* Zentriert die Icons horizontal */
  position: relative; /* Notwendig für die Positionierung des Tooltips */
}

.side-menu-button:hover {
  background-color: #1abc9c;
}

.side-menu-button.active {
  background-color: #16a085;
}

.menu-icon {
  width: 40px; /* Größere Icons */
  height: 40px;
}

/* Benutzerdefinierter Tooltip */
.custom-tooltip {
  font-size: 1em;
  padding: 8px 12px;
  background-color: #333 !important; /* Hintergrundfarbe des Tooltips */
  color: #fff !important; /* Textfarbe des Tooltips */
  border: 1px solid #555 !important; /* Rahmenfarbe des Tooltips */
  border-radius: 6px !important; /* Abgerundete Ecken */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2) !important; /* Schatten für den Tooltip */
  z-index: 5;
}

.custom-tooltip::after {
  border-top-color: #333 !important; /* Farbe des Pfeils */
}

/* Optional: Transition-Effekt für den Tooltip */
.custom-tooltip {
  transition: all 0.3s ease-in-out;
}

/* Punkte-Anzeige */
.punkte-container {
  margin-top: auto; /* Schiebt die Punkte-Anzeige ans Ende */
  text-align: center;
}

.side-menu h3 {
  font-size: 1.2em;
  margin-top: 20px;
  text-align: center;
  border-top: 1px solid #7f8c8d;
  padding-top: 10px;
}

.side-menu .punkte {
  font-weight: bold;
  color: #1abc9c;
}

/* Popup-Menü für mobile Geräte */
@media (max-width: 768px) {
  .side-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%; /* Popup-Menü erstreckt sich über die Breite des Bildschirms */
    height: 60px;
    flex-direction: row;
    justify-content: space-around;
    border-radius: 0; /* Entfernt die Ecken, um es wie ein Popup aussehen zu lassen */
    padding: 10px;
  }

  .side-menu-button {
    flex-direction: column; /* Macht die Icons vertikal auf kleineren Bildschirmen */
    padding: 5px;
    font-size: 0.8em;
  }

  .menu-icon {
    width: 30px; /* Kleinere Icons für mobile Geräte */
    height: 30px;
  }

  .punkte-container {
    display: none; /* Versteckt die Punkte-Anzeige auf kleinen Bildschirmen */
  }
}
