/* src/components/Rangliste.css */

.rangliste-container {
    padding: 20px;
    background-color: #2c3e50;
    color: #ecf0f1;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: 20px auto; /* Zentriert den Container mit mehr Abstand oben */
    margin-bottom: 50px;
  }
  
  .rangliste-container h2 {
    color: #1abc9c; /* Setzt eine helle Farbe für die Überschrift */
    text-align: center;
  }
  
  .rangliste-container table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .rangliste-container th,
  .rangliste-container td {
    padding: 12px 15px;
    border: 1px solid #34495e;
    text-align: center;
  }
  
  .rangliste-container th {
    background-color: #1abc9c;
    color: #2c3e50;
  }
  
  .rangliste-container tr:nth-child(even) {
    background-color: #34495e;
  }
  
  .rangliste-container tr:hover {
    background-color: #3d566e;
  }
  
  .rangliste-container tbody tr td {
    color: #ecf0f1;
  }
  
  @media (max-width: 768px) {
    .rangliste-container {
      padding: 10px;
      margin: 10px auto;
    }
  
    .rangliste-container h2 {
      font-size: 1.5em;
    }
  
    .rangliste-container table,
    .rangliste-container th,
    .rangliste-container td {
      font-size: 0.9em;
    }
  
    .rangliste-container th,
    .rangliste-container td {
      padding: 8px 10px;
    }
  }
  