/* src/components/LanguageSwitcher.css */

.language-switcher {
    display: flex;
    gap: 10px;
    margin: 10px;
  }
  
  .language-button {
    padding: 5px 10px;
    border: none;
    background-color: #34495e;
    color: #ecf0f1;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .language-button:hover {
    background-color: #1abc9c;
  }
  
  .language-button.active {
    background-color: #16a085;
  }
  