/* src/components/Statistiken.css */

.statistiken-container {
  padding: 20px;
  background-color: #2c3e50;
  color: #ecf0f1;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 20px auto; /* Zentriert den Container mit mehr Abstand oben */
  margin-bottom: 50px;
}

.statistiken-container h2,
.statistiken-container h3 {
  color: #1abc9c; /* Setzt eine helle Farbe für die Überschriften */
  text-align: center;
}

.statistiken-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Abstand zwischen den Elementen */
  justify-content: space-between;
}

.statistiken-grid-item {
  background-color: #34495e;
  padding: 20px;
  border-radius: 5px;
  flex: 1;
  min-width: 45%; /* Mindestbreite 45% für 2 Spalten */
  box-sizing: border-box;
  position: relative;
}

.statistiken-container ul {
  list-style-type: none; /* Entfernt die Standard-Aufzählungspunkte */
  padding: 0;
  margin: 0;
}

.statistiken-container ul li {
  padding: 10px;
  background-color: #3d566e;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.statistiken-container p {
  background-color: #3d566e;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  text-align: center;
  color: #ecf0f1;
}

/* Fortschrittsbalken */
.progress-bar {
  width: 100%;
  background-color: #bdc3c7;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 5px;
}

.progress {
  height: 10px;
  background-color: #1abc9c;
  width: 0%;
}

/* Zusätzliche Stile für Netto-Produktion */
.statistiken-grid-item h3:nth-of-type(3) {
  color: #e74c3c; /* Rot für Netto-Produktion */
}

.statistiken-grid-item .netto {
  color: #e74c3c; /* Rot für Netto-Werte */
}

/* Responsives Layout */
@media (max-width: 768px) {
  .statistiken-grid-item {
    min-width: 100%; /* Volle Breite auf kleinen Bildschirmen */
  }

  .statistiken-container h2 {
    font-size: 1.5em;
  }

  .statistiken-container h3 {
    font-size: 1.2em;
  }

  .statistiken-container ul li,
  .statistiken-container p {
    font-size: 0.9em; /* Kleinere Schriftgröße für mobile Geräte */
  }

  .progress-bar {
    height: 8px;
  }

  .progress {
    height: 8px;
  }
}
