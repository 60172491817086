/* Login.css */

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background: linear-gradient(135deg, #1e3c72, #2a5298); /* Setze den Hintergrund auf den ganzen Body */
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Inhalt beginnt oben */
  min-height: 10vh; /* Volle Viewport-Höhe */
  padding-top: 10px; /* Abstand von oben, um den Inhalt nach unten zu schieben */
  padding: 10px;
  box-sizing: border-box;
  background: linear-gradient(135deg, #1e3c72, #2a5298);
  color: #fff;
}

.login-title {
  font-size: 2.5em;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  color: #ffdd57;
}

.login-button {
  background-color: #ff7f50;
  border: none;
  padding: 12px 25px;
  font-size: 1.2em;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}
  
  .login-button:hover {
    background-color: #ff5722;
  }
  
  .login-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .button-content {
    display: flex;
    align-items: center;
  }
  
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin-right: 10px;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  .description {
    max-width: 800px;
    text-align: center; /* Zentriere den Text */
    margin-top: 30px;
    line-height: 1.6;
    font-size: 1.1em;
  }
  
  .error-message {
    color: #ff6b6b;
    margin-top: 20px;
    font-weight: bold;
  }
  
  .connected-address {
    margin-top: 10px;
    font-size: 1em;
    background: rgba(255, 255, 255, 0.1);
    padding: 10px 20px;
    border-radius: 5px;
  }
  
  @media (max-width: 375px) {
    .login-container {
      padding: 20px;
      height: auto;
      padding-top: 50px;
    }
  
    .login-title {
      font-size: 2em;
      margin-top: 20px;
      margin-bottom: 15px;
    }
  
    .login-button {
      padding: 10px 20px;
      font-size: 1em;
    }
  
    .description {
      font-size: 0.9em;
      line-height: 1.4;
      margin-top: 20px;
      text-align: center; /* Text für kleine Bildschirme zentrieren */
    }
  
    .connected-address {
      font-size: 0.9em;
      padding: 5px 15px;
    }
  
    .error-message {
      font-size: 0.9em;
      margin-top: 10px;
    }
  
    .button-content {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .spinner {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }

  @media (min-width: 376px) and (max-width: 768px) {
    /* Für Tablets und mittelgroße Bildschirme */
    .login-container {
      padding: 50px;
      height: auto;
      padding-top: 80px;
    }
  
    .login-title {
      font-size: 2.5em;
      margin-top: 30px;
      margin-bottom: 20px;
    }
  
    .login-button {
      padding: 12px 25px;
      font-size: 1.1em;
    }
  
    .description {
      font-size: 1em;
      line-height: 1.5;
      margin-top: 25px;
      text-align: center;
    }
  
    .connected-address {
      font-size: 1em;
      padding: 10px 20px;
    }
  
    .error-message {
      font-size: 1em;
      margin-top: 15px;
    }
  
    .button-content {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .spinner {
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
  }
  
  @media (min-width: 769px) {
    /* Für Desktop und große Bildschirme */
    .login-container {
      padding: 200px;
      height: 100vh;
      padding-top: 150px;
    }
  
    .login-title {
      font-size: 3.5em;
      margin-top: 50px;
      margin-bottom: 30px;
    }
  
    .login-button {
      padding: 15px 40px;
      font-size: 1.3em;
    }
  
    .description {
      font-size: 1.2em;
      line-height: 1.8;
      margin-top: 30px;
      text-align: left;
    }
  
    .connected-address {
      font-size: 1.2em;
      padding: 15px 30px;
    }
  
    .error-message {
      font-size: 1.2em;
      margin-top: 20px;
    }
  
    .button-content {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .spinner {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }