/* src/components/Arbeiter.css */

.arbeiter-container {
  padding: 20px;
  background-color: #2c3e50; /* Ähnlich wie .statistiken-container */
  color: #ecf0f1;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 20px auto; /* Zentriert den Container mit Abstand oben */
  margin-bottom: 50px;
}

.arbeiter-container h2,
.arbeiter-container h3 {
  color: #1abc9c; /* Helle Farbe für Überschriften */
  text-align: center;
}

.arbeiter-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  background-color: #34495e; /* Hintergrundfarbe ähnlich .statistiken-grid-item */
  padding: 15px;
  border-radius: 5px;
}

.gebaeude-name {
  flex: 1;
  font-weight: bold;
  color: #ecf0f1;
}

.worker-controls {
  display: flex;
  align-items: center;
}

.worker-controls button {
  width: 30px;
  height: 30px;
  font-size: 18px;
  margin: 0 10px;
  cursor: pointer;
  background-color: #1abc9c; /* Einheitliche Button-Farbe */
  border: none;
  border-radius: 5px;
  color: #ecf0f1;
  transition: background-color 0.3s;
}

.worker-controls button:hover:not(:disabled) {
  background-color: #16a085; /* Dunklere Farbe beim Hover */
}

.worker-controls button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
  background-color: #7f8c8d; /* Farbe für deaktivierte Buttons */
}

.worker-controls span {
  width: 60px;
  text-align: center;
  color: #ecf0f1;
}

.progress-bar {
  width: 100%;
  background-color: #bdc3c7;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 5px;
}

.progress {
  height: 10px;
  background-color: #1abc9c;
  width: 0%;
}

/* Responsives Layout */
@media (max-width: 768px) {
  .arbeiter-container {
      padding: 15px;
  }

  .arbeiter-item {
      flex-direction: column;
      align-items: flex-start;
  }

  .worker-controls {
      margin-top: 10px;
  }

  .worker-controls button {
      margin: 5px;
  }

  .worker-controls span {
      width: 100%;
      text-align: left;
  }
}
